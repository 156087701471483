import React from 'react';
import { Stack } from '@mui/material';
import {styled} from '@mui/material/styles';
import telegram from '../images/telegram.webp';
import x from '../images/x.webp';
import dex from '../images/dex.webp';
import pill from '../images/pill.webp';

const pairAddress = 'AQi2eNBXchs9899aavN9dg7oCNr1FGqjn8kmed5fhLp7';
const teleLink = `https://t.me/catinder_sol`;
const xLink = "https://twitter.com/catinder_sol";
const dexLink = `https://dexscreener.com/solana/${pairAddress}`;
//const ca = 'D3gwNP2x9wNbv1YqXbeWg2mgMFNSMXe3no61uKZaEFim';
//const pumpfunLink = `https://pump.fun/${ca}`;

const HoverImage = styled('img')({
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.2)',
    },
    cursor: "pointer"
});
  

function Socials({size1, size2, size3}) {
  return (
    <Stack direction="row" alignItems="center" spacing={2.25} >
        <HoverImage src={telegram} alt={"telegram"} width={size1} height="auto" onClick={() => window.open(teleLink, "_blank")} />
        <HoverImage src={x} alt={"x"} width={size2} height="auto" onClick={() => window.open(xLink, "_blank")} />
        <HoverImage src={dex} alt={"dexscreener"} width={size3} height="auto" onClick={() => window.open(dexLink, "_blank")} /> 
    </Stack>
  );
}

export default Socials;
