import './Home.css';
import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Box, Snackbar, Stack, Typography, Avatar, IconButton, useMediaQuery, Card, Divider, Button, TextField, Modal } from '@mui/material';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ChatBubbleComponent from './common/ChatBubble';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';

import sadCat from './images/sadcat.webp';

import getFormattedDate from './utils/dateConvert';

import SideStack from './common/SideStack';
import Socials from './common/Socials';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import michi from './images/michi.webp';

import html2canvas from 'html2canvas';

const ca = 'D3gwNP2x9wNbv1YqXbeWg2mgMFNSMXe3no61uKZaEFim';
const pairAddress = '3LFqfU1NCyxvd6VUvPByod2V1NMtuAPRHVyMPFV8vrAH';

const RotatedIcon = styled(StraightenOutlinedIcon)({
    transform: 'rotate(90deg)',
});

/*const GradientTypography = styled(Typography)({
    background: 'linear-gradient(45deg, #e00069, orange)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});*/

const GradientButton = styled(Button)({
    background: 'linear-gradient(45deg, #e00069, orange)',
    borderRadius: '50px',
    color: 'white',
    padding: '10px 20px',
    fontWeight: 600,
    width: '100px',
    fontSize: '1rem',
    height: '42px',
});

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4a4a4a',
        borderRadius: '25px',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
  }));

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function ClipboardComponent() {
  const [open, setOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(ca)
      .then(() => {
        setOpen(true);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleCopy}
        sx={{ cursor: 'pointer', border: '3px solid white', px: 2, pb: 0.5, borderRadius: 2 }}
      >
        <Typography fontSize="5vh">
          ca: {ca}
        </Typography>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Copied to clipboard"
      />
    </Box>
  );
}

function Home() {
  const [profiles, setProfiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [matchedCat, setMatchedCat] = useState(null);
  const [curProfile, setCurProfile] = useState(null);
  const [messages, setMessages] = useState([]);
  const modalRef = useRef();
  const chatContainerRef = useRef(null);
  const mobile = useMediaQuery('(max-width:915px)');
  const [messaging, setMessaging] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [miles, setMiles] = useState(getRandomInt(1, 100));

  const defaultProfiles = [
    {
        "name": "Pawsley",
        "job": "Furmer",
        "age": 21,
        "location": "Meowbourne",
        "height": "3 ft 7 in",
        "bio": "Swipe right if you like: sunbeams, chin scritches, and judging people from a distance.  #HairballNotIncluded",
        "filePath": "cats/Pawsley.webp",
        "response": "That was a purr-etty bad attempt.",
        "message": "Are you a furball? Because you've got me coughing up my feelings."
    },
    {
        "name": "Meowra",
        "job": "Clawyer",
        "age": 22,
        "location": "Catlanta",
        "height": "2 ft 10 in",
        "bio": "Single meowdel here. Seeking human for can-opening & lap-warming duties. Must enjoy chasing lasers & occasional sassy side-eye.  #PurrfectMatchGuaranteed",
        "filePath": "cats/Meowra.webp",
        "response": "L rizz",
        "message": "Are you a laser pointer? Because I can't stop chasing after you."
    },
    {
        "name": "Whiskerette",
        "job": "Meowchanical Engineer ",
        "age": 23,
        "location": "Meowdrid",
        "height": "3 ft 0 in",
        "bio": "Single meowdel here. Seeking human for can-opening & lap-warming duties. Must enjoy chasing lasers & occasional sassy side-eye.  #PurrfectMatchGuaranteed",
        "filePath": "cats/Whiskerette.webp",
        "response": "Paw-lease, that was awful.",
        "message": "https://media1.tenor.com/m/5BYK-WS0__gAAAAC/cool-fun.gif"
    },
    {
        "name": "Purrlie",
        "job": "Meowccountant",
        "age": 19,
        "location": "Purris",
        "height": "3 ft 3 in",
        "bio": "Single meowdel here. Seeking human for can-opening & lap-warming duties. Must enjoy chasing lasers & occasional sassy side-eye.  #PurrfectMatchGuaranteed",
        "filePath": "cats/Purrlie.webp",
        "response": "You've got to be kitten me",
        "message": "Are you a cat? Because you've purr-manently caught my attention."
    }
  ]

  function meowify(str) {
    // Split the string into an array of words
    const words = str.split(/\s+/);  // Split on one or more whitespace characters
  
    // Replace each word with "meow"
    const meowedWords = words.map(word => "meow");
  
    // Join the meowed words back into a string with spaces
    return meowedWords.join(" ");
  }
  

  useEffect(() => {
    // Fetch the JSON data
    fetch('/CatProfiles.json')
      .then(response => response.json())
      .then(data => {
        // Shuffle the data
        const shuffledData = data.sort(() => 0.5 - Math.random());
        setProfiles(shuffledData);
      })
      .catch(error => {
        console.error('Error fetching the profiles:', error);
      });
  }, []);

  const handleDislike = () => {
    if (currentIndex < profiles.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(profiles.length);
    }
  };

  const handleLike = () => {
    setMatchedCat(profiles[currentIndex]);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setMessaging(true);
  };

  const handleRestart = () => {
    const shuffledData = profiles.sort(() => 0.5 - Math.random());
    setProfiles(shuffledData);
    setCurrentIndex(0);
  };

  const handleScreenshot = () => {
    if (modalRef.current) {
      html2canvas(modalRef.current).then(canvas => {
        const link = document.createElement('a');
        link.download = 'catinder_match.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
  };

  const handleSendMessage = (event) => {
    if (textValue.trim() && (event.key === 'Enter' || event.type === 'click')) {
        // Instantly show the user's message
        const newMessages = [...messages, { text: meowify(textValue), sender: true }];
        setMessages(newMessages);
        setTextValue('');
        //scroll to bottom
        // Scroll to the bottom of the chat
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 100;
        }

        // After 1 second, show the '...'
        setTimeout(() => {
            const typingIndicatorMessages = [...newMessages, { text: '...', sender: false }];
            setMessages(typingIndicatorMessages);

            

            // After another 2 seconds, replace '...' with the GIF
            setTimeout(() => {
                const gifMessages = [...newMessages, { text: messages.length < 3 ? <img src="https://i.pinimg.com/originals/b4/9d/20/b49d20d0df6421ac97215064a9c694e5.gif" alt="GIF" style={{width: mobile ? '60vw' : '36vw'}}/> : "meow", sender: false }];
                setMessages(gifMessages);
                if (chatContainerRef.current) {
                  chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 100;
                }
            }, 2000);
            // Scroll to the bottom of the chat
            if (chatContainerRef.current) {
              chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 100;
            }
        }, 1000);
    }
};

const handleProfileClick = (profile) => {
    //set matchedCat to the profile
    setCurProfile(profile);
};

  useEffect(() => {
    if (matchedCat) {
      setCurProfile(matchedCat);
      setMiles(getRandomInt(1, 100));
      setMessages([{ text: 'meowww', sender: false }]);
    }
  }, [matchedCat]);

  //useEffect for messages
  useEffect(() => {
    /*if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.text === "...") {
        setTimeout(() => {
          setMessages([...messages.slice(0, -1), { text: "meow", sender: true }]);
        }, 1000);
      }
    }*/
    console.log(messages)
  }, [messages]);

  return (
    <Stack alignItems="center" spacing={1} p={0} overflowY='none'>
        { !messaging ?
        <>
        <Box height={mobile ? 1 : 2}/>
        <Socials size1="50px" size2="38px" size3="40px"/>
        <Box height={mobile ? '1vh' : '2vh'}/>
        {currentIndex < profiles.length ? (
            <>
          <Card sx={{ width: mobile ? '72vw' : '22vw', bgcolor:"#171717", maxWidth: '500px' }}>
              <img src={profiles[currentIndex]?.filePath} alt={profiles[currentIndex].name} width="100%" style={{height:"60vh"}} />
              <Typography textAlign="center" fontWeight={700} sx={{mb: 1, fontSize: mobile ? 22 : 24}}>
                  {profiles[currentIndex].name}, {profiles[currentIndex].age}
              </Typography>
              <Typography textAlign="center" sx={{mb: 1, fontSize: mobile ? 14 : 16}}>
                  {profiles[currentIndex].job} | {profiles[currentIndex].location}
              </Typography>
          </Card>
          <Stack direction="row" spacing={3} alignItems="center" pt={mobile ? 0 : 1}>
                <IconButton onClick={handleDislike} disabled={currentIndex >= profiles.length}>
                    <CancelRoundedIcon sx={{color: "#c9495e", fontSize: mobile ? "40px" : "60px"}}/>
                </IconButton>
                <IconButton onClick={handleLike} disabled={currentIndex >= profiles.length}>
                    <FavoriteRoundedIcon sx={{color: "#6dc949", fontSize: mobile ? "40px" : "60px"}}/>
                </IconButton>
            </Stack>
            </>
        ) : (
          <Stack textAlign="center" >
            <img src={sadCat} alt="sad cat" style={{marginBottom: '20px', width:"30vw"}} height="auto" />
            <Typography fontWeight={700} sx={{mb: 2, fontSize: mobile ? 22 : 24}}>
              You've run out of matches.
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Box flex={1}/>
                <GradientButton onClick={handleRestart} sx={{width: '20vw'}}>
                Keep Looking
                </GradientButton>
                <Box flex={1}/>
            </Stack>
          </Stack>
        )}</> :
        ( mobile ? 
          <Stack width='100vw' height="90vh">
                <Stack direction="row" width="100%" height="12vh" padding={2} alignItems="center" sx={{ background: 'linear-gradient(45deg, #e00069, orange)' }}>
                    <Avatar alt="profile" src={michi} sx={{height: '44px', width: '44px'}} />
                    <Typography sx={{ ml: 1, fontWeight: 600 }}>Michi</Typography>
                    <Box flex={1} />
                    <Socials size1="42px" size2="32px" size3="34px" />
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" width="100%" px={2} height="12vh" >
                    <Avatar alt="match" src={curProfile?.filePath} sx={{border: '2px solid white', width: '60px', height: '60px'}} />
                    <Typography fontSize={18} fontWeight={500} sx={{color: 'darkgray'}}> You matched with {curProfile?.name} on {getFormattedDate(Date.now())}</Typography>
                    <Box flex={1} />
                </Stack>
                <Divider sx={{bgcolor:"#4a4a4a"}}/>
                <Stack height="76vh" px={2} py={4}  sx={{ overflowY: 'auto' }} ref={chatContainerRef}>
                    
                    {curProfile !== matchedCat ? 
                    <>
                    <ChatBubbleComponent text={curProfile.message.includes('.gif') ? <img src={curProfile.message} alt="GIF" style={{width:'10vw'}}/> : curProfile.message } sender={true} avatar={curProfile.sender ? null : curProfile?.filePath} />
                    <ChatBubbleComponent text={curProfile.response} sender={false} avatar={curProfile.sender ? null : curProfile?.filePath} />
                    <Typography fontSize='1vw'>{curProfile?.name} has unmatched with you.</Typography> 
                    </>
                    :
                      (messages.map((message, index) => (
                        <ChatBubbleComponent key={index} text={message.text} sender={message.sender} avatar={message.sender ? null : curProfile?.filePath} />
                    )))
                    }
                    <Box flex={1} />
                </Stack>
                <Divider  sx={{bgcolor:"#4a4a4a"}}/>
                <Stack height="12vh" p={2} direction="row" alignItems="center" spacing={1}>
                    <CustomTextField placeholder="Type a message" fullWidth={true} value={textValue} disabled={curProfile !== matchedCat}
                    onKeyDown={handleSendMessage} 
                    onChange={(e)=> setTextValue(e.target.value)} />
                    <GradientButton onClick={handleSendMessage} disabled={textValue.length === 0}> Send </GradientButton>
                </Stack>
            </Stack>
          : 
        <Stack direction="row">
            <Stack width="25vw" height="100vh">
                <Stack direction="row" width="100%" height="12vh" padding={2} alignItems="center" sx={{ background: 'linear-gradient(45deg, #e00069, orange)' }}>
                    <Avatar alt="profile" src={michi} sx={{height: '3.2vw', width: '3.2vw'}} />
                    <Typography fontSize={'1.2vw'}  sx={{ ml: 1, fontWeight: 600 }}>Michi</Typography>
                    <Box flex={1} />
                    <Socials size1="42px" size2="32px" size3="34px" />
                </Stack>
                <Stack width="100%">
                    <Box onClick={() => handleProfileClick(matchedCat)}>
                      <SideStack name={matchedCat?.name} message="meowww" image={matchedCat?.filePath} main={true} />
                    </Box>
                    {defaultProfiles.map((profile, index) => (
                      <Box key={index} onClick={() => handleProfileClick(profile)}>
                        <SideStack key={index} name={profile.name} message={profile.response} image={profile.filePath}/>
                      </Box>
                    ))}
                </Stack>
                <Stack bgcolor="#171717" flex={1} position="relative">
                    <Divider orientation="vertical" sx={{ bgcolor: "#4a4a4a", position: 'absolute', height: '100%', width: '1px', right: 0 }} />
                </Stack>
            </Stack>
            <Stack width='50vw' height="100vh">
                <Stack direction="row" spacing={2} alignItems="center" width="100%" px={2} height="12vh" >
                    <Avatar alt="match" src={curProfile?.filePath} sx={{border: '2px solid white', width: '3.6vw', height: '3.6vw'}} />
                    <Typography fontSize={mobile ? 18 : '1.5vw'} fontWeight={500} sx={{color: 'darkgray'}}> You matched with {curProfile?.name} on {getFormattedDate(Date.now())}</Typography>
                    <Box flex={1} />
                </Stack>
                <Divider sx={{bgcolor:"#4a4a4a"}}/>
                <Stack height="76vh" px={2} py={4}  sx={{ overflowY: 'auto' }} ref={chatContainerRef}>
                    
                    {curProfile !== matchedCat ? 
                    <>
                    <ChatBubbleComponent text={curProfile.message.includes('.gif') ? <img src={curProfile.message} alt="GIF" style={{width:'10vw'}}/> : curProfile.message } sender={true} avatar={curProfile.sender ? null : curProfile?.filePath} />
                    <ChatBubbleComponent text={curProfile.response} sender={false} avatar={curProfile.sender ? null : curProfile?.filePath} />
                    <Typography fontSize='1vw'>{curProfile?.name} has unmatched with you.</Typography> 
                    </>
                    :
                      (messages.map((message, index) => (
                        <ChatBubbleComponent key={index} text={message.text} sender={message.sender} avatar={message.sender ? null : curProfile?.filePath} />
                    )))
                    }
                    <Box flex={1} />
                </Stack>
                <Divider  sx={{bgcolor:"#4a4a4a"}}/>
                <Stack height="12vh" p={2} direction="row" alignItems="center" spacing={1}>
                    <CustomTextField placeholder="Type a message" fullWidth={true} value={textValue} disabled={curProfile !== matchedCat}
                    onKeyDown={handleSendMessage} 
                    onChange={(e)=> setTextValue(e.target.value)} />
                    <GradientButton onClick={handleSendMessage} disabled={textValue.length === 0}> Send </GradientButton>
                </Stack>
            </Stack>
            <Stack width='25vw' height='100vh'>
                <img src={curProfile?.filePath} alt="match" width="100%" style={{height:'70vh'}}/>
                <Stack direction="row" height='100%'>
                    <Divider orientation="vertical" flexItem  sx={{bgcolor:"#4a4a4a" , marginRight: 2}}/>
                    <Stack spacing={1} mt={1} height='100%'>
                        <Typography fontWeight="bold" sx={{fontSize: "2.5vw", }}>{curProfile?.name} <span style={{fontSize: "2vw"}}>{curProfile?.age}</span></Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <WorkOutlineOutlinedIcon sx={{color:"gray"}}/>
                            <Typography fontSize='1.2vw' fontWeight="bold" sx={{color:"gray"}}>{curProfile?.job}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <RotatedIcon sx={{color:"gray"}} />
                            <Typography fontSize='1.2vw' fontWeight="bold" sx={{color:"gray"}}>{curProfile?.height}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <HouseOutlinedIcon sx={{color:"gray"}} />
                            <Typography fontSize='1.2vw' fontWeight="bold" sx={{color:"gray"}}>{curProfile?.location}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <LocationOnOutlinedIcon sx={{color:"gray"}} />
                            <Typography fontSize='1.2vw' fontWeight="bold" sx={{color:"gray"}}>{miles} miles away</Typography>
                        </Stack>
                        <Box flex={1} height="100%"/>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>)
        }
        <Modal open={modalOpen} onClose={handleModalClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box
            ref={modalRef}
            width={mobile ? '80vw' : '25vw'}
            maxWidth='500px'
            position="absolute"
            bgcolor="white"
            p={2}
            pb={1}
            borderRadius={2}
            boxShadow={24}
          >
            <Stack direction="row" alignItems="center" spacing={0} pb={1} pl='3%'>
              <Box flex={1}/>
                <img src="/catinder.webp" alt="catinder logo" width={mobile ? '70px' : "90px"} height="auto" style={{marginLeft: -10}} />
                <Typography 
                sx={{fontWeight: 600, fontSize: mobile ? 36 : 44, color: "#e82a59", fontFamily: 'GothamRounded, Arial'}}>
                    catinder
                </Typography>
                <Box flex={1}/>
            </Stack>
            
            <img src={matchedCat?.filePath} alt="match" width='100%' style={{height:'60vh', borderRadius: 4}}></img>
            <Typography variant="h5" textAlign="center" fontWeight="bold" sx={{color: "black", mb: mobile ? 1 : 2}}>
              You matched with {matchedCat?.name}!
            </Typography>
            <Stack direction="row">
              <Box flex={1}/>
              <GradientButton sx={{width: '300px'}} endIcon={<QuestionAnswerRoundedIcon/>} onClick={handleModalClose} >Start Chatting</GradientButton>
              <Box flex={1}/>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0} pt={mobile ? 0.25 : 1}>
                <Box flex={1}/>
                <Typography sx={{color: 'black', pr:1}}>Share your match!</Typography>
                <IconButton onClick={handleScreenshot}>
                    <CameraAltOutlinedIcon sx={{color: "black", fontSize: 30}}/>
                </IconButton>
                <Box flex={1}/>
            </Stack>
          </Box>
        </Modal>
    </Stack>
  );
}

export default Home;
